import React from 'react';
import { withRouter } from 'react-router-dom';

import './../../../assets/scss/style.scss';
import Aux from "../../../hoc/_Aux";
import Breadcrumb from "../../../App/layout/AdminLayout/Breadcrumb";


class SignUp1 extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            username: '',
            password: ''
        };
    }

    usernameInputChanged = (event) => {
        this.setState({
            username: event.target.value
        });
    }

    passwordInputChanged = (event) => {
        this.setState({
            password: event.target.value
        });
    }

    loginTry = () => {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                username: this.state.username,
                password: this.state.password
            })
        };
    
        fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.access_token) {
                    // Save the access token in local storage (or wherever you want to store it)
                    localStorage.setItem('access_token', data.access_token);

                    let path = `/authentication/login`;

                    if(data.radnja === undefined) {
                        this.props.history.push()
                    }
                    else if(data.radnja === 'online_prodaja' || data.radnja === 'student') {
                        path = '/posiljke/online-prodaja'
                    }
                    else if(data.radnja === 'skenderija' || data.radnja === 'dobrinja' || data.radnja === 'bulevar') {
                        path = '/posiljke/radnja-obrada'
                    }
                    else if(data.radnja === 'administracija') {
                        path = '/posiljke/administracija-obrada'
                    }
    
                    // Redirect to the desired page 
                    this.props.history.push(path);
                } else {
                    // Handle error (show error message, etc.)
                    console.error('Invalid username or password');
                }
            });
    }

    render () {
        return(
            <Aux>
                <Breadcrumb/>
                <div className="auth-wrapper">
                    <div className="auth-content">
                        <div className="auth-bg">
                            <span className="r"/>
                            <span className="r s"/>
                            <span className="r s"/>
                            <span className="r"/>
                        </div>
                        <div className="card">
                            <div className="card-body text-center">
                                <div className="mb-4">
                                    <i className="feather icon-unlock auth-icon"/>
                                </div>
                                <h3 className="mb-4">Login</h3>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="Username" onChange={this.usernameInputChanged} />
                                </div>
                                <div className="input-group mb-4">
                                    <input type="password" className="form-control" placeholder="Password" onChange={this.passwordInputChanged}/>
                                </div>
                                
                                
                                <button className="btn btn-primary shadow-2 mb-4" onClick={this.loginTry}>Login</button>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }
}

export default withRouter(SignUp1); 