const menu_items = {

    skenderija: [
        {
            id: 'posiljke_skenderija',
            title: 'Pošiljke',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'posiljke_radnja_obrada',
                    title: 'Obrada pošiljki',
                    type: 'item',
                    url: '/posiljke/radnja-obrada',
                    icon: 'feather icon-home',
                },
                {
                    id: 'posiljke_radnja_provjeriti',
                    title: 'Pošiljke za provjeriti',
                    type: 'item',
                    url: '/posiljke/radnja-provjeriti',
                    icon: 'feather icon-home',
                },
                {
                    id: 'posiljke_radnja_zavrsene',
                    title: 'Završene pošiljke',
                    type: 'item',
                    url: '/posiljke/radnja-zavrsene',
                    icon: 'feather icon-home',
                }
            ]
        },

        {
            id: 'skenderija_prenos',
            title: 'Prenos',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'posiljkeItem9',
                    title: 'Prenosne pošiljke',
                    type: 'item',
                    url: '/posiljke/prenosne-posiljke',
                    icon: 'feather icon-home',
                }
            ]
        },

        {
            id: 'pakovanje_skenderija',
            title: 'Pakovanje',
            type: 'group',
            icon: 'icon-navigation',

            children: [
                {
                    id: 'pakovanje_posiljke',
                    title: 'Pakovanje pošiljki',
                    type: 'item',
                    url: '/posiljke/pakovanje-posiljke',
                    icon: 'feather icon-home',
                },
                {
                    id: 'zapakovane_posiljke',
                    title: 'Zapakovane pošiljke',
                    type: 'item',
                    url: '/posiljke/zapakovane-posiljke',
                    icon: 'feather icon-home',
                },
            ]
        },

        {
            id: 'kasa_skenderija',
            title: 'Kasa',
            type: 'group',
            icon: 'icon-navigation',

            children: [
                {
                    id: 'racuni_za_izmjenu_item',
                    title: 'Računi za izmjenu',
                    type: 'item',
                    url: '/posiljke/racuni-rucna-izmjena',
                    icon: 'feather icon-home',
                },
                {
                    id: 'posiljkeItem7',
                    title: 'Export kasa',
                    type: 'item',
                    url: '/posiljke/export-kasa',
                    icon: 'feather icon-home',
                },
                {
                    id: 'posiljkeItem6',
                    title: 'Suma otkupnina',
                    type: 'item',
                    url: '/posiljke/suma-otkupnina',
                    icon: 'feather icon-home',
                }
            ]
        },

        {
            id: 'ostalo_skenderija',
            title: 'Ostalo',
            type: 'group',
            icon: 'icon-navigation',

            children: [
                {
                    id: 'posiljkeItem10',
                    title: 'Zaključi dan',
                    type: 'item',
                    url: '/posiljke/zakljuci-dan',
                    icon: 'feather icon-home',
                }
            ]
        }
    ],

    dobrinja: [
        {
            id: 'posiljke',
            title: 'Pošiljke',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'posiljke_radnja_obrada',
                    title: 'Obrada pošiljki',
                    type: 'item',
                    url: '/posiljke/radnja-obrada',
                    icon: 'feather icon-home',
                },
                {
                    id: 'posiljke_radnja_provjeriti',
                    title: 'Pošiljke za provjeriti',
                    type: 'item',
                    url: '/posiljke/radnja-provjeriti',
                    icon: 'feather icon-home',
                },
                {
                    id: 'posiljke_radnja_zavrsene',
                    title: 'Završene pošiljke',
                    type: 'item',
                    url: '/posiljke/radnja-zavrsene',
                    icon: 'feather icon-home',
                }
            ]
        },

        {
            id: 'dobrinja_prenos',
            title: 'Prenos',
            type: 'group',
            icon: 'icon-navigation',

            children: [
                {
                    id: 'posiljkeItem8',
                    title: 'Export prenosnica',
                    type: 'item',
                    url: '/posiljke/export-prenosnica',
                    icon: 'feather icon-home',
                }
            ]
        },

        {
            id: 'dobrinja_ostalo',
            title: 'Ostalo',
            type: 'group',
            icon: 'icon-navigation',

            children: [
                {
                    id: 'posiljkeItem11',
                    title: 'Taxi',
                    type: 'item',
                    url: '/posiljke/taxi',
                    icon: 'feather icon-home',
                },
                {
                    id: 'posiljkeItem10',
                    title: 'Zaključi dan',
                    type: 'item',
                    url: '/posiljke/zakljuci-dan',
                    icon: 'feather icon-home',
                }
            ]
        }
    ],

    bulevar: [
        {
            id: 'bulevar_posiljke',
            title: 'Pošiljke',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'posiljke_radnja_obrada',
                    title: 'Obrada pošiljki',
                    type: 'item',
                    url: '/posiljke/radnja-obrada',
                    icon: 'feather icon-home',
                },
                {
                    id: 'posiljke_radnja_provjeriti',
                    title: 'Pošiljke za provjeriti',
                    type: 'item',
                    url: '/posiljke/radnja-provjeriti',
                    icon: 'feather icon-home',
                },
                {
                    id: 'posiljke_radnja_zavrsene',
                    title: 'Završene pošiljke',
                    type: 'item',
                    url: '/posiljke/radnja-zavrsene',
                    icon: 'feather icon-home',
                }
            ]
        },

        {
            id: 'bulevar_prenos',
            title: 'Pošiljke',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'posiljkeItem8',
                    title: 'Export prenosnica',
                    type: 'item',
                    url: '/posiljke/export-prenosnica',
                    icon: 'feather icon-home',
                }
            ]
        },

        {
            id: 'bulevar_ostalo',
            title: 'Pošiljke',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'posiljkeItem11',
                    title: 'Taxi',
                    type: 'item',
                    url: '/posiljke/taxi',
                    icon: 'feather icon-home',
                },
                {
                    id: 'posiljkeItem10',
                    title: 'Zaključi dan',
                    type: 'item',
                    url: '/posiljke/zakljuci-dan',
                    icon: 'feather icon-home',
                }
            ]
        }
    ],

    administracija: [
        {
            id: 'administracija_posiljke',
            title: 'Pošiljke',
            type: 'group',
            icon: 'icon-navigation',

            children: [
                {
                    id: 'posiljke_admin_obrada',
                    title: 'Nabavka',
                    type: 'item',
                    url: '/posiljke/administracija-obrada',
                    icon: 'feather icon-home',
                },
                {
                    id: 'posiljke_admin_provjeriti',
                    title: 'Pošiljke za provjeru',
                    type: 'item',
                    url: '/posiljke/administracija-provjeriti',
                    icon: 'feather icon-home',
                },
                {
                    id: 'posiljke_admin_zavrsene',
                    title: 'Završene pošiljke',
                    type: 'item',
                    url: '/posiljke/administracija-zavrsene',
                    icon: 'feather icon-home',
                },
                {
                    id: 'posiljke_admin_pomjerene',
                    title: 'Pomjerene pošiljke',
                    type: 'item',
                    url: '/posiljke/administracija-pomjerene',
                    icon: 'feather icon-home',
                }
            ]
        },

        {
            id: 'administracija_ostalo',
            title: 'Pošiljke',
            type: 'group',
            icon: 'icon-navigation',

            children: [
                {
                    id: 'posiljkeItem11',
                    title: 'Prenos robe',
                    type: 'item',
                    url: '/prenos-robe',
                    icon: 'feather icon-home',
                },
                {
                    id: 'posiljkeItem10',
                    title: 'Zaključi dan',
                    type: 'item',
                    url: '/posiljke/zakljuci-dan',
                    icon: 'feather icon-home',
                }
            ]
        }
    ],

    online_prodaja: [
        {
            id: 'online_prodaja_posiljke',
            title: 'Pošiljke',
            type: 'group',
            icon: 'icon-navigation',

            children: [
                {
                    id: 'posiljkeItem1',
                    title: 'Dodaj pošiljku',
                    type: 'item',
                    url: '/posiljke/online-prodaja',
                    icon: 'feather icon-home',
                },
                {
                    id: 'posiljkeItem2',
                    title: 'Obrada pošiljki',
                    type: 'item',
                    url: '/posiljke/online-prodaja-obrada',
                    icon: 'feather icon-home',
                },
                {
                    id: 'onlineProdajaZavrseneItem',
                    title: 'Završene pošiljke',
                    type: 'item',
                    url: '/posiljke/online-prodaja-zavrsene',
                    icon: 'feather icon-home',
                },
                {
                    id: 'onlineProdajaProvjeritiItem',
                    title: 'Pošiljke za provjeru',
                    type: 'item',
                    url: '/posiljke/online-prodaja-provjera',
                    icon: 'feather icon-home',
                },
                {
                    id: 'kontrolnaProvjeritiItem',
                    title: 'Kontrolne pošiljke',
                    type: 'item',
                    url: '/posiljke/online-prodaja-kontrolne',
                    icon: 'feather icon-home',
                },
                {
                    id: 'onlineProdajaPomjereneItem',
                    title: 'Pomjerene pošiljke',
                    type: 'item',
                    url: '/posiljke/online-prodaja-promjerene',
                    icon: 'feather icon-home',
                },
                {
                    id: 'onlineProdajaObavijestitiItem',
                    title: 'Obavijestiti kupca',
                    type: 'item',
                    url: '/posiljke/online-prodaja-obavijestiti',
                    icon: 'feather icon-home',
                },
                {
                    id: 'onlineProdajaSvePosiljkeItem',
                    title: 'Sve pošiljke',
                    type: 'item',
                    url: '/posiljke/online-prodaja-sve-posiljke',
                    icon: 'feather icon-home',
                }
            ]
        },

        {
            id: 'online_prodaja_ostalo',
            title: 'Ostalo',
            type: 'group',
            icon: 'icon-navigation',

            children: [
                {
                    id: 'posiljkeItem222',
                    title: 'Export pošta',
                    type: 'item',
                    url: '/posiljke/export-posta',
                    icon: 'feather icon-home',
                },
                {
                    id: 'stakla_mapiranje',
                    title: 'Mapiranje stakala',
                    type: 'item',
                    url: '/posiljke/stakla-mapiranje',
                    icon: 'feather icon-home',
                },
                {
                    id: 'posiljkeItem10',
                    title: 'Ostalo',
                    type: 'item',
                    url: '/posiljke/zakljuci-dan',
                    icon: 'feather icon-home',
                }
            ]
        }
    ],

    student: [
        {
            id: 'student_prodaja_posiljke',
            title: 'Pošiljke',
            type: 'group',
            icon: 'icon-navigation',

            children: [
                {
                    id: 'student_dodaj_posiljku',
                    title: 'Dodaj pošiljku',
                    type: 'item',
                    url: '/posiljke/online-prodaja',
                    icon: 'feather icon-home',
                },
            ]
        }
    ],

    // Leave this to avoid having to fix BreadCrumbs that throws an error, even though we don't need them
    items: [
    ]
}

export default menu_items;